import { GatsbyBrowser } from 'gatsby';

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location);

  if (!location.hash) {
    setTimeout(() => {
      window.scrollTo(...(currentPosition || [0, 0]));
    }, 0);

    return false;
  }

  return true;
};
